:root {
  --footer-bg-color: #f0f0f0;
  --footer-text-color: #000;
  --footer-hover-bg-color: #f5f5f5;
  --footer-hover-text-color: #d32f2f;
  --footer-padding: 20px;
  --footer-margin: 20px;
  --footer-font: 'Poppins', sans-serif;
}

.footer {
  background-color: var(--footer-bg-color);
  color: var(--footer-text-color);
  padding: var(--footer-padding);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: var(--footer-font);
}

.footer-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: var(--footer-margin);
  max-width: 1024px;
}

.footer-section {
  flex: 1;
  min-width: 200px;
}

.links-section,
.social-section,
.subscribe-section {
  text-align: center;
}

.subscribe-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.subscribe-section h2 {
  margin-right: 10px;
  white-space: nowrap;
}

.subscribe-section input {
  margin-right: 10px;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .subscribe-section {
    flex-direction: column;
    align-items: center;
  }

  .subscribe-section h2,
  .subscribe-section input,
  .subscribe-section .button {
    margin: 5px 0;
    width: 100%;
  }

  #mc_embed_signup_scroll {
    flex-direction: column;
    align-items: center;
  }

  #mc_embed_signup .mc-field-group {
    width: 100%;
  }
}

.footer-section h3 {
  font-size: 1.2em;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin: 5px 0px 0px 0px;
}

.footer-section ul li a {
  color: var(--footer-text-color);
  text-decoration: none;
}

.footer .social-icons {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.footer .social-icons li {
  margin-right: 10px;
}

.footer .social-icons a {
  color: var(--footer-text-color);
  font-size: 1.5em;
}

.footer .social-icons a:hover {
  color: var(--footer-text-color);
}

#mc_embed_signup {
  background: transparent;
  clear: left;
  font: 14px var(--footer-font);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#mc_embed_signup_scroll {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#mc_embed_signup .mc-field-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#mc_embed_signup input[type="email"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: var(--footer-font);
}

#mc_embed_signup .button {
  padding: 10px;
  background-color: #fff;
  color: var(--footer-text-color);
  border: 2px solid var(--footer-text-color);
  border-radius: 20px;
  cursor: pointer;
  font-family: var(--footer-font);
}

#mc_embed_signup .button:hover {
  background-color: var(--footer-hover-bg-color);
}

.footer-bottom {
  width: 100%;
  text-align: center;
  background-color: var(--footer-bg-color);
  padding: 10px 0;
  margin-top: var(--footer-margin);
}

.footer a:hover {
  background-color: var(--footer-hover-bg-color);
  border-radius: 5px;
}
