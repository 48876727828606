/* global.css */
:root {
  --primary-color: #007bff;
  --bg-color: #fff;
  --text-color: #333;
  --font-family: 'Poppins', sans-serif;
}

body {
  font-family: var(--font-family);
  background-color: var(--bg-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: var(--primary-color);
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.home .home-content {
  max-width: none;
  margin: 0;
  padding: 0;
}


/* global.css */
:root {
  --header-bg-color-home: black;
  --header-bg-color-video: white;
  --header-bg-color-audio: #f0f0f0;
  --header-bg-color-about: #e0e0e0;
  --header-bg-color-not-found: black;
  --header-bg-color-default: white;
}

.header {
  background-color: var(--header-bg-color-default);
  transition: background-color 0.3s ease;
}

.header.home {
  background-color: var(--header-bg-color-home);
}
.header.video {
  background-color: var(--header-bg-color-video);
}
.header.audio {
  background-color: var(--header-bg-color-audio);
}
.header.about {
  background-color: var(--header-bg-color-about);
}
.header.not-found {
  background-color: var(--header-bg-color-not-found);
}
