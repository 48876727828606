:root {
  --menu-bg-color: white;
  --menu-text-color: black;
  --overlay-bg-color: rgba(0, 0, 0, 0.3);
  --hover-bg-color: #f5f5f5;
  --hover-text-color: #d32f2f;
}

.bm-menu {
  background: var(--menu-bg-color);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  color: var(--menu-text-color);
}

.bm-menu-open {
  transform: translateX(0);
}

.bm-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--overlay-bg-color);
  z-index: 999;
  display: none;
}

.bm-overlay-open {
  display: block;
}

.bm-logo {
  width: 90%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  padding: 0 5%;
}

.bm-logo-image {
  max-width: 90%;
  height: auto;
}

.bm-logo:hover {
  background-color: var(--hover-bg-color);
}

.bm-item-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bm-item-list a {
  color: var(--menu-text-color);
  text-decoration: none;
  margin-bottom: 1rem;
  font-size: 1.5em;
  padding: 10px 0;
}

.bm-item-list a:hover {
  background-color: var(--hover-bg-color);
  color: var(--hover-text-color);
}

.bm-social-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.bm-social-icons a {
  margin-bottom: 10px;
  color: var(--menu-text-color);
  font-size: 1.5em;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
}

.bm-social-icons a:hover {
  background-color: var(--hover-bg-color);
  color: var(--hover-text-color);
}

.bm-menu .bm-logo:hover {
  background-color: #e0e0e0;
}
