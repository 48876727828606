:root {
  --dark-bg-color: black;
  --light-bg-color: white;
  --text-color-light: black;
  --text-color-dark: #FDF3EF;
  --hover-bg-color: #f5f5f5;
  --hover-text-color: #ccc;
  --light-hover-bg-color: #f5f5f5;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  transition: background-color 0.3s, color 0.3s;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
}

.logo {
  text-decoration: none;
  font-size: 1.5em;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.logo-image {
  height: auto;
  max-height: 50px;
}

.menu {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.menu a {
  text-decoration: none;
  font-size: 20px;
  margin-left: 10px;
}

.darkBackground {
  background-color: var(--dark-bg-color);
  color: var(--text-color-dark);
}

.lightBackground {
  background-color: var(--light-bg-color);
  color: var(--text-color-light);
}

.darkBackground .menu a {
  color: var(--text-color-dark);
}

.lightBackground .menu a {
  color: var(--text-color-light);
}

.header a {
  text-decoration: none;
  padding: 3px 5px;
}

.header a:hover {
  background-color: var(--hover-bg-color);
  border-radius: 5px;
}

.lightBackground .header a:hover {
  background-color: var(--light-hover-bg-color);
}

.header .social-icons {
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 5px;
  height: 100%;
}

.header .social-icons a {
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.darkBackground .social-icons a {
  color: var(--text-color-dark);
}

.lightBackground .social-icons a {
  color: var(--text-color-light);
}

.lightBackground .menu a:hover {
  color: var(--hover-text-color);
}

.darkBackground .menu a:hover {
  color: var(--hover-text-color);
}

.hamburger-menu {
  display: none;
}

.hamburger-menu-light {
  color: var(--text-color-light);
}

.hamburger-menu-dark {
  color: var(--text-color-dark);
}

@media (max-width: 768px) {
  .menu {
    display: none;
  }

  .hamburger-menu {
    display: block;
  }
}
